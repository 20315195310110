<template>
    <div class="action-premium-rating">
        <h3>hello world</h3>
    </div>
</template>

<script>
export default {
    name: 'ActionPremiumRating',
    props: ['message'],
}
</script>

<style scoped></style>
